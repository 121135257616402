<template>
  <MainNavbar />
  <!-- Contact form  -->
  <div class="container-fluid pt-5 px-0">
    <img src="privacy-policy.jpg" class="w-100 main-image" alt="">
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-center">Must Read</h5>
        <h1 class="text-center">Diginlink Privacy Policy</h1>
        <p>
          Diginlink’s priority is to promote the use of its activity to gain
          users confidence and trust and to do so it has publicized its
          practices which are defined as followed:
        </p>
        <p>
          The privacy policy elucidates the type of information is collected on
          the website. It explains how the organization collects the information
          and uses it how the website shares it and what choice it offers you
          regarding the collection use and dispensation of information. The
          privacy policy further assures how about the security measures to
          prevent the misuse of the information and how many inaccuracies can be
          made rectified.
        </p>
        <p>
          To make it convenient for you the policy has been divided into four
          major sections that are applicable to the general, public, publishers,
          merchants, or users which can be studied as required.
        </p>
        <h3>Keeping the information protected.</h3>
        <p>
          The information on Diginlink stored on a reliable Firewall protected
          service that has a patient in complete protection. The Personal
          information is guarded by the latest up-to-date technologies in
          security features from unauthorized access or use. The access to the
          privacy policy is only intended for link’s staff while backups are
          constantly established to ensure minimum loss of personal information.
        </p>
        <p>
          However, despite the fact that all the security measures are strictly
          in control of internally by Diginlink it is a known fact that any
          information on the internet is not 100% secured and thus it should be
          in the knowledge of the user that the information is shared at its own
          risk.
        </p>
        <h3>Use of Cookies</h3>
        <p>
          A cookie is&nbsp;a small piece of text sent to your browser by a
          website you visit. It helps the site to remember information about
          your visit, which can make it easier to visit the site again and make
          the site more useful to you. There are three types of computer cookies
          session cookies and persistent cookies and third party cookies.
        </p>
        <p>
          Session cookies make the navigation of the website easy and expire
          when the browser is closed. Persistent cookies on the other hand
          remain on the hard disc for a long duration of time. The primary
          functions of Persistent cookies are to understand your preference and
          to work to accentuate your experience. Persistent cookies can be
          removed by the browser’s “clear cookies” function.
        </p>
        <p>
          A third-party cookie is&nbsp;placed on a website by someone other than
          the owner (a third party) and collects user data for the third party.
          As with standard cookies, third-party cookies are placed so that a
          site can remember something about the user at a later time.
        </p>
        <h3>Information shared with third parties</h3>
        <p>
          Diginlink use third parties and analytics Technologies by which
          cookies are dropped on the website and collect non-personally
          identifiable information about the user in order to understand their
          use and access to the website. The website has no access to or control
          over these cookies and agrees to the privacy policy you agree to the
          cookies being dropped over the device by which Diginlink has accessed
          to.
        </p>
        <h3>Alterations in the privacy policy</h3>
        <p>
          Diginlink has the right to alter and make changes in the privacy
          policy at any time so it is recommended to view it frequently. If the
          changes are made to the policies and the publisher is in merchants
          will be notified by the means of publisher Hub and merchant hub
          respectively.
        </p>
        <h2>Publisher</h2>
        <h3>Curated Information by Diginlink</h3>
        <p>
          When a publisher signs up at Diginlink, the following are the kinds of
          personally identifiable information that are collected by the website.
          Full name of the publisher of the publisher contact if the two names
          are different.
        </p>
        <ul>
          <li>email address</li>
          <li>telephone number</li>
          <li>website information</li>
          <li>Bank / PayPal/ Payoneer details</li>
          <li>payment and VAT information</li>
          <li>house address</li>
        </ul>
        <p>How you came to know about Diginlink.</p>
        <p>
          Apart from the aforementioned information, Diginlink curates
          information as to when and how a publisher uses the publisher hub
          include other reports tools and interfaces that are provided by the
          website.
        </p>
        <h3>Collected information used by Diginlink</h3>
        <p>
          Diginlink use the information to enforce its obligations under the
          convention to evaluate the property of Diginlink enabled sites for the
          service and to contact the publisher to offer them help regarding the
          registration adoption and the development of the services.
        </p>
        <p>
          The collected information is also used to pay the publisher the
          percentage of the commission that is earned through the services and
          for any interaction regarding any Diginlink related matters.
        </p>
        <p>
          Diginlink collect the information to render the publisher with the
          update about the performance and billing and to ameliorate the
          services which are provided to the publishers by Diginlink based on
          how they used in the past.
        </p>
        <p>
          The information of publishers is also used to introduce them to the
          merchants and advertisers for any further purchases or exchange of
          services.
        </p>
        <h3>Editing the information</h3>
        <p>
          The information provided to Diginlink can be edited by the publisher
          within the publisher hub. Furthermore, it is the responsibility of the
          publisher to log off the publisher hub once not in use to keep the
          password confidential and to protect the information against any
          illegitimate use and access.
        </p>
        <h3>Publishers and their privacy policy</h3>
        <p>
          It is the responsibility of the publisher to ensure that privacy
          policy, “about us” and other related and corresponding section to the
          website should contain the following salient features and at the
          publisher on the user’s approval for each of these clauses:
        </p>
        <p>
          1. A statement regarding the use of the service of the Diginlink
          enabled site that as ”Our website accommodates some of the affiliate
          marketing Links i.e. any sales made on the service after that we write
          about earns us Commission are articles in content however no influence
          by such advertiser affiliate affiliation.
        </p>
        <p>
          2. The precise information about the cookies that are dropped on the
          user’s computer by the means of the third parties analytic
          Technologies when a user accesses the website or clicks the affiliate
          link Diginlink enabled sites and publisher toolbars. Furthermore, it
          is also the duty of the publisher to include in the privacy policy
          information regarding the disabling of Diginlink Optimization cookies.
        </p>
        <h3>Share with third parties</h3>
        <p>
          It is made a player that Diginlink might share a certain part of the
          publisher’s information with the partners and advertisers to
          accentuate the mutual relation between the advertiser and the
          merchants in turn leading to the increment of revenue share for the
          Publishers.
        </p>
        <p>The date that can be disclosed by Diginlink conditions</p>
        <p>
          1. When Diginlink observes a potential commercial relationship between
          the publisher and merchant.
        </p>
        <p>
          2. When a sale or purchase of a business or an asset is made the
          information is to be close to the buyer- seller to the business or
          asset.
        </p>
        <p>
          3. When we hold the responsibility to disclose your personal
          information and sharing to satisfy the court or jurisdiction of the
          competent Government and other legal obligations for the application
          or application of the Diginlink service requirements for the
          protection of the right, property, or security Diginlink or our Client
          or others.
        </p>
        <h3>Merchant</h3>
        <p>What kind of information does Diginlink curate?</p>
        <p>
          Following is the type of Personally Identifiable Information that is
          collected by the website when a merchant signs up to avail the
          services.
        </p>
        <p>
          Full name of the Merchant and, if different, the name of the merchant
          contact
        </p>

        <ul>
          <li>email address</li>
          <li>telephone number</li>
          <li>website information</li>
          <li>Bank / PayPal/ Payoneer details</li>
          <li>payment and VAT information</li>
          <li>house address</li>
        </ul>
        <p>
          Apart from the aforementioned information, Diginlink curates
          information as to when and how a Merchant uses the Merchant Hub
          include other reports, tools and interfaces that are provided by the
          website.
        </p>
        <h3>Collected Information used by Diginlink</h3>
        <p>
          Diginlink collect the information to render the Merchant with the
          update about the performance and billing and to ameliorate the
          services which are provided to the Merchant by Diginlink based on how
          they used in the past.
        </p>
        <p>
          Diginlink use the information to enforce its obligations under the
          convention to evaluate the property of Merchant for the services and
          to contact the Merchant to offer them help regarding the registration
          adoption and the development of the services.
        </p>
        <p>
          The collected information is also used to bill the Merchant their
          percentage of the commission that is billed for the services and for
          any interaction regarding any Diginlink related matters.
        </p>
        <p>
          The information is also used to introduce them with the Publishers for
          any further purchases or exchange of services.
        </p>
        <h3>Editing the information</h3>
        <p>
          The information provided to Diginlink can be edited by the Merchant
          within the Merchant Hub.
        </p>
        <p>
          Furthermore, it is the responsibility of the Merchant to log off the
          Merchant Hub once not in use to keep the password confidential and to
          protect the information against any illegitimate use and access.
        </p>
        <h3>Publishers and their privacy policy</h3>
        <p>
          It is the responsibility of the publishers to ensure that the privacy
          policy “about us” and other related and corresponded sections of the
          website should contain the following silent features and that the
          merchants on the user’s approval on each of the following information
          the precise information about the cookies that are dropped on user’s
          computer by the means of third parties analytics Technology when a
          user accesses the website of clicks the affiliate link nestling enable
          sites and publisher toolbars.
        </p>
        <p>
          Furthermore, it is also the duty of the merchant to include in the
          privacy policy information regarding disabling the use of Diginlink
          Optimization cookies.
        </p>
        <h3>Share with third parties</h3>
        <p>
          Diginlink keeps its priority to safeguard your personally identifiable
          information and would never disclose it without your consent however
          these are conditions in which information would be revealed to the
          third parties such as
        </p>
        <p>
          1. When Diginlink observes a potential commercial relationship between
          the publisher and merchant.
        </p>
        <p>
          2. When a sale or purchase of a business or an asset is made the
          information is to be close to the buyer- seller to the business or
          asset.
        </p>
        <p>
          3. When we hold the responsibility to disclose your personal
          information and sharing to satisfy the court or jurisdiction of the
          competent Government and other legal obligations of for the
          application or application of the Diginlink service requirements for
          the protection of the right, property, or security Diginlink or our
          Client or others.
        </p>
        <h2>User</h2>
        <h3>What kind of information does Diginlink curate for users?</h3>
        <p>
          This section deals with how Diginlink functions to the Diginlink
          enabled sites and publisher toolbars and how it collects the user’s
          information
        </p>
        <h3>What is Diginlink?</h3>
        <p>
          Diginlink rendered services to publishers which their avail to earn
          revenue and information through their commerce-related content. In
          simple terms, it is a process by which a publisher generates a link on
          the content available to the user. When the link is clicked, the user
          is transported to the Merchant’s website and if a purchase is made,
          yields publisher a referral fee by the merchant via Diginlink.
        </p>
        <p>
          The referral fee has no link with the price that the user page not
          does it influences its experience with the merchant.
        </p>
        <h3>Collection and use of the user’s information</h3>
        <p>
          Diginlink does not collect the user’s personally identifiable
          information deliberately or on the purpose.
        </p>
        <p>However, regarding the non-personally identifiable information:</p>
        <p>
          The User’s IP address is stored to undermine its geographical location
          which helps in the provision of an adequate and suitable link and also
          to mitigate any chances of fraud or non-human browsing attempts.
        </p>
        <p>
          The information related to the device through which the users access
          the site within the publisher’s network to understand and fix any
          issue with ease and to provide the publisher merchants and the
          advertisers and use as a preferred browsing environment.
        </p>
        <p>
          The information related to the entire website a user visits within the
          publisher network which help us discern the user’s interest by
          providing the relevant links in advertising to the merchants and
          advertisers.
        </p>
      </div>
    </div>
  </div>
  <FooterContent />
</template>
<script>
import MainNavbar from "@/components/MainNavbar";
import FooterContent from "@/components/FooterContent.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    FooterContent: FooterContent,
    MainNavbar: MainNavbar,
  },
};
</script>
<style scoped>
/************** Contact Us ************/
.main-image{
  height: 70vh;
  object-fit: cover;
  object-position: center;
}
</style>