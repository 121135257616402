<template>
  <nav class="navbar navbar-expand-lg bg-transparent">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="/diginlink-logo-1.png" style="width: 150px; margin-left:50px" alt="">
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse flex-grow-0 mx-auto" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link fw-semibold active" aria-current="page" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link fw-semibold" to="/about-us">About Us</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link fw-semibold" to="/advertiser">Advertiser</router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link fw-semibold" to="/publisher">Publisher</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link fw-semibold" to="/influencer">Influencer</router-link>
          </li>
        </ul>
      </div>
      <div class="d-flex gap-2">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-primary dropdown-toggle rounded-pill" data-bs-toggle="dropdown" aria-expanded="false">
            Register
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="https://affiliate.diginlink.com/influencer/register">As Influencer</a></li>
            <li><a class="dropdown-item" href="https://affiliate.diginlink.com/register">As Publisher</a></li>
          </ul>
        </div>
        <a target="_blank" href="https://affiliate.diginlink.com/login" class="btn btn-warning rounded-pill px-4">Sign In</a>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'MainNavbar'
};
</script>