<template>
    <MainNavbar />

    <div class="container my-5 what-is-digilink">
      <div class="row py-5">
        
        <div class="col-md-6">
            <h6>Imprint</h6>
          <h2>Diginlink Inc</h2>
          <p class="display-8 fw-bold">Our Canada Address</p>
          <p class="text-start display-6">

                  7274 Gagliano Dr <br />
                  Mississauga, ON L5W 1X3, Canada
                  <br /><br />
                  Business Number 789460953 <br />
                </p>
                 
                  <p class="text-start mt-5">
                  Company Contact:<br />
                  <span class="text-primary">
                    Phone: +1548-708-0829
                  </span><br />
                  <span class="text-primary">
                    Email: Support@Diginlink.com 
                  </span><br />
                  <span class="text-primary">
                    Website: Diginlink.com 
                  </span>
                </p>
        </div>
        <div class="col-md-6">
            <h6>Imprint</h6>
         <h2>Diginlink LLC</h2>
         <p class="display-8 fw-bold">Our US Address</p>
         <p class="text-start display-6">
           
           1500 Champlain ,Crest Way,<br />Cary, North Carolina 27513,
                  United States
                  <br /><br />
                 Company
                  EIN 99-1464886<br /></p>
               
                  <p class="text-start mt-5">
                  Company Contact:<br />
                  <span class="text-primary">
                    Phone: +1548-708-0829
                  </span><br />
                  <span class="text-primary">
                    Email: Support@Diginlink.com 
                  </span><br />
                  <span class="text-primary">
                    Website: Diginlink.com 
                  </span>
                </p>
       </div>
      </div>
    </div>
    <FooterContent />
  </template>
    
    <script>
  import MainNavbar from "@/components/MainNavbar";
  import FooterContent from "@/components/FooterContent.vue";
  export default {
    name: "AboutUs",
    components: {
      FooterContent: FooterContent,
      MainNavbar: MainNavbar,
    },
  };
  </script>
    
  <style scoped>
  .bg-about h2,
  h6 {
    font-weight: bold;
  }
  .bg-about p {
    font-weight: 400;
  }
  .bg-about.container-fluid {
    padding: 150px;
    color: white;
    text-align: center;
    background: url("../../public/about-us-bg.jpg") rgba(0, 0, 0, 0.529) no-repeat
      center;
    background-blend-mode: color;
    background-size: cover;
  }
  .what-is-digilink h2,
  h6 {
    font-weight: bold;
  }
  </style>
    