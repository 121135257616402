<template>
  <MainNavbar />
  <div class="container-fluid bg-about">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <h6>About Us</h6>
        <h2>About Diginlink</h2>
        <p>
          Diginlink is an affiliate network that works with over 15,000
          advertisers agencies. We are a network of networks that serves as a
          one-stop-shop for those who value convenience. Those who want all
          their affiliate links, payments, technology, and support services from
          one team need not go anywhere else.
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-12 my-5">
        <img src="about-us-brands.jpeg" class="w-100" alt="" />
      </div>
    </div>
  </div>

  <div class="container my-5 what-is-digilink">
    <div class="row py-5">
      <div class="col-md-6">
        <h6>About Us</h6>
        <h2>About Diglink</h2>
        <p class="display-6">Make Money While You Sleep</p>
        <p>
          Diginlink rendered services to publishers which their avail to earn
          revenue and information through their commerce-related content. In
          simple terms, it is a process by which a publisher generates a link on
          the content available to the user. When the link is clicked, the user
          is transported to the Merchant’s website and if a purchase is made,
          yields publisher a referral fee by the merchant via Diginlink. <br />
          <br />
          The referral fee has no link with the price that the user page not
          does it influences its experience with the merchant.
        </p>
      </div>
      <div class="col-md-6">
        <img src="about-pic.jpeg" class="rounded-4 shadow" width="100%" />
      </div>
      <div class="col-md-12 my-5 pt-5">
        <h1 class="text-center mb-4">Empower your business with Diginlink</h1>
        We are a team of engineers, designers and product enthusiasts driven by
        a goal to bring authenticity back into the world of product promotion.
        Our platform offers a wide set of features for both creators and brands,
        reducing the friction required to find and work with the brands the
        creators genuinely love, not just those who will send them a paycheck.
        The product started out of a research project at MIT and has evolved to
        the comprehensive platform it is today through hard work by the highly
        motivated team below. Our team is based in Boston, and we work with
        experts and creatives all over the world.
      </div>
    </div>
  </div>
  <FooterContent />
</template>
  
  <script>
import MainNavbar from "@/components/MainNavbar";
import FooterContent from "@/components/FooterContent.vue";
export default {
  name: "AboutUs",
  components: {
    FooterContent: FooterContent,
    MainNavbar: MainNavbar,
  },
};
</script>
  
<style scoped>
.bg-about h2,
h6 {
  font-weight: bold;
}
.bg-about p {
  font-weight: 400;
}
.bg-about.container-fluid {
  padding: 150px;
  color: white;
  text-align: center;
  background: url("../../public/about-us-bg.jpg") rgba(0, 0, 0, 0.529) no-repeat
    center;
  background-blend-mode: color;
  background-size: cover;
}
.what-is-digilink h2,
h6 {
  font-weight: bold;
}
</style>
  