<template>
  <footer class="container-fluid py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <router-link class="navbar-brand" to="/">
            <img src="/diginlink-logo-light.png" style="width: 150px" alt="" />
          </router-link>
          <p class="my-2">
            The industry leader in partnership management solutions empowering
            brands or marketers to unlock scalable growth through end-to-end
            software solutions and services.
          </p>
        </div>
        <div class="col-md-2">
          <h3 class="text-light fw-normal mb-2">Solutions</h3>
          <router-link class="footer-links" to="/about-us"
            >About Us</router-link
          >
          <router-link class="footer-links" to="/publisher"
            >Publisher</router-link
          >
          <router-link class="footer-links" to="/influencer"
            >Influencers</router-link
          >
        </div>
        <div class="col-md-2">
          <h3 class="text-light fw-normal mb-2">Information</h3>
         
          <router-link class="footer-links" to="/imprint"
            >Imprint</router-link
          >
          <a class="footer-links" target="_blank" href="./media-kit.pdf"
            >Media Kit</a
          >
          <a class="footer-links" target="_blank" href="./influencer-demo-graphic.pdf"
            >Influencer Demographic</a
          >
        </div>
        <div class="col-md-2">
          <h3 class="text-light fw-normal mb-2">Support</h3>
          <router-link class="footer-links" to="/contact-us"
            >Contact Us</router-link
          >
          <router-link class="footer-links" to="/privacy-policy"
            >Privacy Policy</router-link
          >
          <router-link class="footer-links" to="/terms-conditions"
            >Terms of Use</router-link
          >
        </div>
        <div class="col-md-3 text-center pt-4 mt-2">
          <a href="https://www.facebook.com/diginlink"><img src="footer/icons8-facebook-100.png" class="w-25" alt=""></a>
          <a href="https://www.instagram.com/diginlinkofficial/"><img src="footer/icons8-instagram-100.png" class="w-25" alt="" /></a>
          <a href="https://www.linkedin.com/in/digin-link-043138252/"><img src="footer/icons8-linkedin-100.png" class="w-25" alt="" /></a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterContent",
};
</script>


<style scoped>
footer {
  color: #7e8389;
  background-color: #182740;
}
.footer-links {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: #7e8389;
}
</style>