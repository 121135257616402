import { createRouter, createWebHistory } from 'vue-router';
import Home from './pages/Home.vue';
import AboutUs from './pages/AboutUs.vue';
import Imprint from './pages/Imprint.vue';
import Publisher from './pages/PublisherPage.vue';
import Influencer from './pages/InfluencerPage.vue';
import ContactUs from './pages/ContactUs.vue';
import PrivacyPolicy from './pages/PrivacyPolicy.vue';
import TermsConditions from './pages/TermsConditions.vue';
import AccountVerification from './pages/AccountVerification.vue';
const routes = [
  { path: '/', component: Home } ,
  { path: '/about-us', component: AboutUs } ,
  { path: '/publisher', component: Publisher },
  { path: '/influencer', component: Influencer },
  { path: '/kol', component: Influencer },
  { path: '/contact-us', component: ContactUs },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/terms-conditions', component: TermsConditions },
  { path: '/imprint', component: Imprint },
  { path: '/account-verify', component:AccountVerification}
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
