import { createApp } from 'vue';
import App from './App.vue';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'font-awesome/css/font-awesome.css';
import 'gsap/CSSPlugin'
import router from './router';

createApp(App).use(router).mount('#app')

