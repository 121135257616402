<template>
  <MainNavbar />
  <div class="bg-dark">
    <div class="container publisher-main">
      <div class="row py-5">
        <div class="col-md-5 d-flex flex-wrap align-content-center">
          <h1 class="heading">Become a Publisher</h1>
          <p class="my-4">
            Connect with the world's leading brands and efficiently monetize
            your content through true partnerships.
          </p>
          <a href="https://affiliate.diginlink.com/register" class="btn btn-primary">Sign up now</a>
        </div>
        <div class="col-md-7">
          <img
            src="../../public/publisher/Partners_Collage+Credits.png"
            alt=""
            class="w-100"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="container my-3">
    <img
      src="../../public/publisher/brands-publisher.jpeg"
      alt=""
      class="w-100"
    />
  </div>

  <div class="bg-dark">
    <div class="container text-white partnerboost my-5 py-5">
      <div class="row">
        <div class="col-md-10 text-center mx-auto">
          <h1>Become A Publisher And Connect With Top Leading Brands</h1>
          <p class="my-5 text-gray">
            Connect with the world's leading brands and efficiently monetize
            your content through true partnerships.
          </p>
          <div class="row img-section">
            <div class="col-md-4">
              <img
                src="../../public/publisher/publisher-1.jpeg"
                width="100%"
                alt=""
              />
            </div>
            <div class="col-md-4">
              <img
                src="../../public/publisher/publisher-2.jpeg"
                width="100%"
                alt=""
              />
            </div>
            <div class="col-md-4">
              <img
                src="../../public/publisher/publisher-3.jpeg"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-dark">
    <div class="container creator">
      <div
        class="row align-items-start justify-content-between text-white py-5"
      >
        <div class="col-md-5">
          <h1>Influencers & Creators</h1>
          <p class="creator-para">
            Are you an influencer or creator? Sign up to join the Diginlink
            platform and collaborate with brands you know and love The
            difference between creators and influencers is simple: creators make
            content for the sake of making content and sharing it online.
            Influencers make content to grow their personal brand and highlight
            their lives on social media.
          </p>
          <a class="btn btn-primary" href="https://affiliate.diginlink.com/register">Apply Now</a>
        </div>
        <div class="col-md-6">
          <ul>
            <li>Premium Access to Leading Brands</li>
            <li>Premium Commission Rates</li>
            <li>Personalized Shoppable Links</li>
            <li>Detailed Reporting & Analytics</li>
            <li>Faster Payments</li>
          </ul>
        </div>
      </div>
      <div
        class="row align-items-start justify-content-between text-white py-5"
      >
        <div class="col-md-5">
          <h1>Affiliate Partners</h1>
          <p class="creator-para">
            Research and Identify Niche Influencers: Look for influencers,
            bloggers, or content creators in your niche who have an engaged and
            relevant audience. Their unique content style and audience alignment
            can make them valuable affiliate partners.
            <br />
            Engage with Micro-Influencers: While big-name influencers have their
            benefits, micro-influencers (those with a smaller but highly engaged
            audience) can provide a more personalized touch and unique audience
            connection.
            <br />
            Partner with Content Creators: Seek out individuals who create
            high-quality and unique content, such as videos, blog posts,
            podcasts, or social media content. Their creative approach can set
            them apart from others.
            <br />
            Utilize Affiliate Networks: Join affiliate networks or platforms
            where you can discover potential partners. These platforms often
            have a wide range of affiliates with varying specialties.
          </p>
          <a class="btn btn-primary" href="https://affiliate.diginlink.com/register">Apply Now</a>
        </div>
        <div class="col-md-6">
          <ul>
            <li>Dynamic Commission Rates</li>
            <li>Deep Linking</li>
            <li>Product Level Tracking</li>
            <li>Custom Reporting</li>
            <li>Powerful API</li>
            <li>Flexible Payment Plan</li>
          </ul>
        </div>
        
      </div>
    </div>
  </div>
  <FooterContent />
</template>

<script>
import MainNavbar from "@/components/MainNavbar";
import FooterContent from '@/components/FooterContent.vue'; 
// import $ from 'jquery';
export default {
  name: "PublisherPage",
  components: {
    MainNavbar: MainNavbar,
    FooterContent: FooterContent,
  },

};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fasthand&family=Playfair+Display:ital,wght@1,500&display=swap");
.publisher-main h1.heading {
  font-size: 7ch;
  color: white;
  font-weight: 200 !important;
}
.publisher-main p {
  color: gray;
  margin: 30px 0px;
}
.publisher-main a {
  color: white;
  /* background-color: red; */
  text-decoration: none;
}
.publisher-main .gradient {
  font-weight: 600;
  font-style: italic;
  background: linear-gradient(135deg, #3ea5ff 14%, #1224e6 92%);
  color: transparent;
  font-family: "Playfair Display", serif;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
}
.text-gradient {
  background: linear-gradient(135deg, #3ea5ff 14%, #1224e6 92%);
  color: transparent;
  font-weight: 400;
  -webkit-background-clip: text;
}
.partnerboost .img-section img {
  border-radius: 20px;
}
.creator ul li::marker {
  color: #26b5ff;
  font-size: 50px;
}
.creator ul > li {
  font-size: 30px;
}
.creator .creator-para {
  padding: 40px 0px;
}
.bg-gradient-btn a {
  color: white;
  text-decoration: none;
  padding: 10px 30px;
  font-weight: 400;
  border-radius: 10px;
  background: linear-gradient(128deg, #26b5ff 17%, #7058ff 80%);
}
marquee {
  width: 100%;
}
marquee img {
  margin: 0px 40px;
  width: 20%;
}
</style>