<template>
<MainNavbar/>
<!-- Contact form  -->
   <div class="bg-dark py-5 text-white contact-us">
      <div class="row m-0">
        <div class="col-md-10 mx-auto">
          <div class="row justify-content-between contact-form">
            <div class="col-md-3">
              <p class="heading my-3">Contact Us</p>
              <div class="d-flex my-3 justify-content-between">
                <i class="fa mx-3 fa-location-arrow text-primary" aria-hidden="true"></i>
                <p class="text-start">Diginlink INC<br />
                  7274 Gagliano Dr <br />
                  Mississauga, ON L5W 1X3, Canada</p>
              </div>
              <div class="d-flex my-3 justify-content-between">
                <i class="fa mx-3 fa-location-arrow text-primary" aria-hidden="true"></i>
                <p class="text-start">Diginlink LLC <br /> 
                  1500 Champlain ,Crest Way,<br />Cary, North Carolina 27513,
                  United States</p>
              </div>

              <div class="d-flex my-3">
                <i class="fa mx-3 fa-file-archive-o text-primary" aria-hidden="true"></i>
               
                <p class="text-start">
                  Company Contact:<br />
                  <span class="text-primary">
                    Phone: +1548-708-0829
                  </span><br />
                  <span class="text-primary">
                    Email: Support@Diginlink.com 
                  </span><br />
                  <span class="text-primary">
                    Website: Diginlink.com 
                  </span>
                </p>
              </div>
            </div>
            <div class="col-md-8 d-flex justify-content-center">
              <div class="w-75 ">
                <p class="heading my-3 ">Message Us</p>
                <form action="">
                  <input type="text" class="form-control" placeholder="Name" name="name" id="">
                  <div class="d-flex gap-3">
                    <input type="text" class="form-control" placeholder="Contact Email" name="email" id="">
                    <input type="text" class="form-control" placeholder="Phone Number" name="phone_number" id="">
                  </div>
                  <div class="d-flex gap-3">
                    <select name="" class="form-control" id="">
                      <option value="">I am a</option>
                      <option value="">Publisher</option>
                      <option value="">Brand</option>
                      <option value="">Merchant</option>
                    </select>
                    <input type="text" class="form-control" placeholder="Country" name="country" id="">
                  </div>
                  <input type="text" class="form-control" placeholder="URL of your Channel" name="url_of_channel" id="">
                  <input type="text" class="form-control" placeholder="Subject" name="subject" id="">
                  <textarea name="" class="form-control" placeholder="Message" id="" cols="30" rows="3"></textarea>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
   </div>
<FooterContent/>
</template>
<script>
    import MainNavbar from "@/components/MainNavbar";
    import FooterContent from '@/components/FooterContent.vue'; 
        export default {
        name: "ContactUs",
        components: {
        'FooterContent': FooterContent ,
        'MainNavbar': MainNavbar
        },
    };
</script>
<style scoped>
/************** Contact Us ************/
.contact-form{
  border-radius: 20px;
  /* box-shadow:0px 0px 10px 10px #26B5FF !important; */
}
.contact-form .d-flex p{
  font-size: 20px;
}
.contact-form .heading{
  font-size: 30px !important;
}
.contact-form .fa{
  font-size: 30px;
}
.contact-form input,select{
  margin: 10px 0px;
}
</style>