<template>
  <MainNavbar/>
 <div class="bg-dark">
     <div class="container influencer-main ">
       <div class="row py-5">
         <div class="col-md-6 d-flex flex-wrap align-content-center">
           <h1 class="heading">Become a influencer</h1>
           <p class="my-4">Connect with the world's leading brands and efficiently monetize your content through true partnerships.</p>
           <div class="my-4">
             <a href="https://affiliate.diginlink.com/influencer/register" class="btn btn-primary">Sign up now</a>
           </div>
         </div>
         <div class="col-md-6 pt-4">
           <img src="../../public/influencer/influencer-img.jpeg" alt="" class="w-100 rounded-4" style="max-height: 300px;object-fit: cover;">
         </div>
       </div>
     </div>
   </div>
   <div class="bg-dark">
     <div class="container text-white partnerboost my-5 py-5">
       <div class="row">
         <div class="col-md-10 text-center  mx-auto">
           <h1>Your Content is Powerful Influencers</h1>
           <p class="mb-5 text-gray">Turn it into a monetization vehicle with us</p>
           <div class="row img-section">
             <div class="col-md-8 mx-auto" style="max-height: 500px;">
               <img src="../../public/influencer/influencer-img-2.jpeg" style="object-fit: cover;object-position: top;" height="90%" width="100%" alt="">
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="bg-dark">
     <div class="container creator">
       <div class="row justify-content-between text-white py-5">
         <div class="col-md-6 d-flex flex-column justify-content-center">
           <h1>A brand built on Creator innovation</h1>
           <p class="creator-para">We are driving more than $2B in annual brand sales by uniting Brands, Creators, and Shoppers through the power of distributed original content.</p>
         </div>
         <div class="col-md-6 campaign">
           <img class="rounded-3" src="../../public/influencer/becoming-an-influencer.gif" width="100%" alt="">
         </div>
       </div>
       <div class="row my-5 align-items-start justify-content-between text-white py-5">
         <div class="col-md-6">
           <img class="rounded-3" src="../../public/influencer/influencer-2.jpeg" width="100%" alt="">
         </div>
         <div class="col-md-6 generator">
           <h1>Diginlink Influencer</h1>
           <p class="creator-para">
            Amber Venz Box sits down with some of the top Diginlink Creators who have mastered the art of curating authentic, inviting, and accessible online storefronts that people are drawn to and can’t get enough of. Listen in to hear their take on all things creator, solo-prenuership, and life in general
           </p>
           <div class="bg-gradient-btn">
             <a href="https://affiliate.diginlink.com/influencer/register">Apply Now</a>
           </div>
         </div>
         
       </div>
     </div>
   </div>
  <FooterContent />

</template>

<script>
import MainNavbar from "@/components/MainNavbar";
import FooterContent from '@/components/FooterContent.vue'; 
export default {
 name: "InfluencerPage",
 components: {
   'MainNavbar': MainNavbar,
   FooterContent: FooterContent,
 },
};
</script>
<style scoped>
 @import url('https://fonts.googleapis.com/css2?family=Fasthand&family=Playfair+Display:ital,wght@1,500&display=swap');
 .influencer-main h1.heading{
   font-size:7ch;
   color: white;
   font-weight: 200 !important;
 }
 .influencer-main p{
   color: gray;
   margin: 30px 0px;
 }
 .influencer-main a{
   color: white;
   /* background-color: red; */
   text-decoration: none;
 }
 .influencer-main .gradient{
   font-weight: 600;
   font-style: italic;
   background:linear-gradient(135deg, #3ea5ff 14%, #1224e6 92%);
   color: transparent;
   font-family: 'Playfair Display',serif;
   -webkit-background-clip: text;
   -moz-background-clip: text;
   -o-background-clip: text;
 }
 .text-gradient{
   background:linear-gradient(135deg, #3ea5ff 14%, #1224e6 92%);
   color: transparent;
   font-weight: 400;
   -webkit-background-clip: text;
 }
 .partnerboost .img-section img{
     border-radius: 20px;
 }
 .creator ul li::marker{
   color: #26B5FF;
   font-size: 50px;
 }
 .creator ul > li{
   font-size: 30px;
 }
 .creator .creator-para{
   padding: 40px 0px;
 }
 .bg-gradient-btn a{
   color: white;
   text-decoration: none;
   padding: 10px 30px;
   font-weight: 400;
   border-radius: 10px;
   background: linear-gradient(128deg, #26b5ff 17%, #7058ff 80%);
 }
 marquee{
   width: 100%;
 }
 marquee img{
   margin: 0px 40px ;
   width: 20%;
}
.bg-dark:has(.campaign,.generator) img[class='shadow']{
  box-shadow:0px 0px 10px 10px #26B5FF !important;
  border-radius: 10px !important;
}
.influencer-section-img{
  border-radius: 50% 50% 0 0;
  aspect-ratio: 2/2;
}



</style>









