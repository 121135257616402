<template>
  <MainNavbar />
  <!-- Contact form  -->
  <div class="container-fluid pt-5 px-0">
    <img src="Terms-and-Conditions-Agreement.jpg" class="w-100 main-image" alt="" />
  </div>
  <div class="container py-5">
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-center">Please read it Carefully</h5>
        <h1 class="text-center">Terms and Conditions</h1>
        <p>
          The publisher Membership Agreement is used to monitor the
          participation in the network. It is running in their understanding
          click on other languages “OK” box or similar income. I think you will
          agree that the effective date of this agreement is when you click “OK”
          on the date. To print a copy of this agreement, use the print order
          using the browser.
        </p>

        <p>
          Please note that you do not click on this agreement or through the
          commission, which is not subject to the terms of this Agreement. It
          should be noted that this convention is attached as supplemental
          appendices 1 to 4. Please carefully check all the supplementary
          accessories.
        </p>

        <p>
          This Publisher Membership Agreement is between you (“you”) and
          Diginlink , a limited liability company organized and existing under
          the laws of Delaware, United States of America, except that if your
          business is headquartered in, or, if you are an individual, you reside
          in:
        </p>
        <p>
          1. In European Economic Area or the Swiss Economic Zone, the agreement
          between you and Diginlink Interaction INC. established between
          Internet Marketing Europe Limited, a limited liability company and in
          the country for the purposes of this convention is the publisher
          taking into account the European region.
        </p>
        <p>
          2. Australia, then this agreement is between you and Diginlink and
          Organisation owned by the company in accordance with Australian law
          for the purposes of this agreement will be considered ”Australian
          region publisher.”
        </p>
        <p>
          3. Any of the following countries: China, Indonesia, Hong Kong Japan,
          India, South Korea, Malaysia, Philippian, Pakistan, Singapore, Taiwan,
          Thailand, or Vietnam then this agreement is between you and Diginlink
          Australia and for the purpose of this agreement they will be
          considered ”Asian region publishers.”
        </p>
        <p>
          4. Brazil, then this agreement is between you Diginlink is either the
          establishment of a limited liability company and they and the purpose
          of the law in Brazil and the purpose of this agreement is considered
          by the “Brazilian regional editor”.
        </p>
        <p>
          If you have registered or accepted the name of the unit on behalf of
          the company, it is believed that this agreement has been accepted on
          behalf of these companies.
        </p>
        <p>
          This means that the convention deals with the “Agreement” of the
          publishers, individuals, and communities, with the context and all the
          political and policy guidance of the region’s specific payment such as
          payment policy in the region of Brazil or the network (the “network
          policy”), as in fact occasionally.
        </p>
        <p>
          The Network Policies can be accessed by clicking or such other links
          that we may advise you from time to time. In view of the other factors
          and good values contained in this document, they have confirmed and
          received sufficient mutual commitments and agreements, and both
          parties agree that they intend to abide by the law, as follows:
        </p>

        <h2>1. JOINING THE NETWORK</h2>

        <h4>1.1 Registration</h4>

        <p>
          To use (or reuse) the network as a publisher network do you need to
          provide information to Diginlink on the registration of the integrity
          of the market, accurate and complete. If this information is changed,
          you must update your registration information immediately.
        </p>

        <h4>1.2 Accurate Registration Information</h4>

        <p>
          Diginlink on the right to check the validity and accuracy of the
          registration information at any time. It should be noted that if
          Diginlink finding indicate information to be misleading inaccurate
          then Diginlink can have your account restricted and or Access denied
          service and use. Diginlink can also pays Commission to stop and or
          other fees you can pay, or you can, and you can evaluate this related
          amount in the study to Diginlink activities and or confirm this
          information and or otherwise comply with this convention.
        </p>

        <h4>1.3 Participation</h4>
        <p>
          To access the network, you must have at least one person aged 18 a
          legal person, must provide the computer equipment and internet access.
        </p>
        <h4>1.4 Use of the Internet</h4>
        <p>
          If you have already registered as an employee, you acknowledge that
          the Diginlink service is free of charge to provide a network of
          services for connection services to facilitate exclusive business
          transactions and accepted just to use the web trade in order to
          facilitate advertising for your company and other uses. They also
          confirm that the use of that network that is involved in the company
          does not operate as a consumer.
        </p>

        <h2>2. Define terms</h2>
        <h4>2.1. The following terms are the meaning indicated:</h4>
        <p>
          Advertiser: explain who owns and manages the website and or other
          companies through the internet to obtain the customer or any other and
          uses of any person.
        </p>

        <p>
          Content: means information, data, documents, text, software, sound,
          music, photograph, graphic, and video
        </p>
        <p>
          A Corporate affiliate is one who is directly or indirectly controlled
          by the person or person or under the same control and it’s such a
          person’s control does not lead to ”control” of the direct power or
          indirect possession of any other person that is the management and
          human policy direction.
        </p>
        <p>
          An end-user means that the consumer or the actual or potential
          customers or others.
        </p>
        <p>
          Engagement: means at any time in your contract agreement with the
          advertising network or in some cases agreement with you or Diginlink
          which may be initiated via the internet or converted or included
          institute in member marketing currently linked to online tracking
          monitoring activities.
        </p>
        <p>
          An Entity means that a legal person from a legal person of their own
          age is a recognized person or a company or a limited liability company
          or a reliable institution or governmental agency or other property.
        </p>
        <p>
          The words “include”, “includes”, and “including” shall be deemed to be
          followed by the phrase “without limitation”.
        </p>
        <p>
          Intellectual Property Rights copyright, content, documentation,
          training manuals, and other materials, as well as all patents, trade,
          secrets, trademarks, and copyrights of copyright and moral rights and
          other Intellectual Property Rights and property, whether it is
          registered or not, and so on. A link means that any program code or
          script or programming technique or another method (or any combination
          thereof) (1) establish as a connection between two sides or (2) to
          show the access path in the ”Logo” or ”user” or ”text” a word or
          phrase of the logo or text material or graphic others when someone is
          activated, resulting in a page template that this person or person can
          access to online content, products, services or links to other pages
          offer. Network means that the affiliate network marketing online
          network means running Diginlink interaction incorporation by
          introducing a contract with the network of network editors.
        </p>
        <p>
          The phrase “provided by “Diginlink provided” shall, when used in
          relation to tools, services, resources, or other offerings in compass
          the provision therefore by Diginlink interaction or Diginlink
          interaction related parties.
        </p>
        <p>
          Network advertisers are those who want to join the network and through
          this participation and the use of the appropriate offer, or seek to
          distribute the network publishers and advertiser, close to the network
          advertiser’s contract is affected.
        </p>
        <p>
          Network publisher is one who is involved in the online media and
          through such participation and the use of the appropriate of or would
          like to add them in a contract in the contract or give it available
          for viewing or distribution of compensation for eligible bonds.
          Network Publisher Account Area referred to pages of private networks
          are the areas of the site pages by Diginlink for the convenience of
          the use of formation of the interaction Publication network registered
          to include a specific wallet address from time to time and with it the
          report and participate in the network. Offerings mean that Diginlink
          introduces you related to the interaction or in the technical,
          software, reporting, database, customer service, account management,
          and other customers seminar, Summits, and the education, and Web
          Services, as well as other tools and services as well as other forms
          Diginlink party, can provide or make resources from time to time.
        </p>
        <p>
          A person is to be broadly construed and include any natural person or
          entity prohibited activity means lender or defamation threatening
          harassment or harassment of any of the following acts
        </p>
        <p>
          (a) discrimination is illegal based on race, ethnicity, sex, religion,
          nationality, age, inclination disability or any other basis under
          applicable law
        </p>
        <p>(b) gambling is illegal;</p>
        <p>(c) to see the sale or export or use of illicit substances;</p>
        <p>
          (d) the obscene, pornographic or similar activities or explicit
          activities;
        </p>
        <p>(e) Terrorism, rebellion other unlawful activity;</p>
        <p>
          (f) any material and/or hyperemia downloading of congestion and/or
          other property for sale or other material in any form without the
          permission of the author violation of any of laws or regulations or
          intellectual property or any other rights of any person or entity of
          the owner or entity of the conflict or infringement of this
          intellectual property;
        </p>
        <p>(g) (A) in any way the minor’s damage; or</p>
        <p>
          (j) any fraudulent activity or any person imitating, including any
          interaction Diginlink on behalf of or with any person misleading
          affiliation.
        </p>
        <p>
          A Qualifying link is any type or form of link that is provided
          Diginlink or authorized by the side to display this distribute or be
          arranged at or through the joining and by any technical and or method
          of joining and/or using the exposure of neutral link interaction
          online advertisers monitor and click through the presentation and or
          contract distribution and/or the link to continue and/or other
          activities. The term qualified Bond also refers to any building
          mechanism or equivalent technique that starts when you click on the
          qualifying link to produce the same result.
        </p>
        <p>
          Diginlink related parties mean that the entrepreneurs and the company
          authorized dealers and service providers Diginlink suppliers. Site is
          in other words according to the needs of the context for the web data
          files or Computer databases, email, script, programs, or other
          applications or other news sites in addition to the support of
          documents and software provided or required on the train or connected
          to it the person or operating two sides obtain the work you can have
          every person’s company that is an on contract a whole there is no
          preacher dies to his purpose for his convention by a place. A tracked
          activity means the outcome of any posters required for any progress of
          free agreed order find link qualification activities you can include
          the following types of that advertisers you can try to complete these
          arrangements see for example images of Impressions on no I sell
          products and services are download programs are documents or other
          elements of the process complete the registration or registration or
          open any other form of any account members and coupons currently
          containment or any other work business activities that can be printed
          to track and report.
        </p>
        <p>
          “Web” or “Internet” or ” online” means that Global computer networks
          such as the internet are currently in knowing known including the
          world wide web and all the network behind it is not available for
          wired and wireless other connected devices, platforms or technologies
          to access it.
        </p>

        <h2>3. Participation</h2>

        <h4>3.1 Participation:</h4>
        <p>
          according to the terms of the agreement shows that that connected to
          the network publishers network transactions can use the publisher to
          be on the network. Your contribution is voluntary and participation
          can be at any time. Diginlink explains any advertising network or has
          a request for an application of purchase a promotion service link
          interaction or any online advertising or trade, or its own business,
          Merchandise, product or service or merchandise.
        </p>

        <h4>3.2 Not a Supplier, etc:</h4>
        <p>
          it is not allowed for anybody that not at any time you should use the
          goods or services of the supplier, dealer to Diginlink. The user can
          interpret or find or a part of the interactive needling as an
          incentive to subscribe or pay for compensation not to participate in
          the network or use any products or services offered on offer.
        </p>

        <h4>3.3 prohibited activities:</h4>
        <p>
          related or connected to the website (or part thereof) for its
          participation in the network it is not possible to exercise any
          activity in May constitute or imply that it may facilitate or
          facilitate any prohibited activities.
        </p>

        <h2>4. Qualifying links</h2>
        <h4>4.1 use of qualifying links:</h4>
        <p>
          Ek Lenge is qualified to use Limited in non-volatile form the tracking
          code, defined by Diginlink.
        </p>
        <h4>4.2 valid referrals only:</h4>
        <p>
          developed for use qualified link to provide even consent. Does not
          allow or intentionally that everyone can activate or attempt to
          activate an attempt to influence the amount result of an in his own
          Limited combination of the results of the required or orbit, including
          but not limited to you to the use of any method or technology that
          does not actually give the user a permanent connection target site
          with this link.
        </p>
        <h4>4.3 final and binding determinations:</h4>
        <p>
          Diginlink's determination as to whether attract activity resulted from
          a qualifying shall be final and binding on you.
        </p>
        <h4>
          4.4 distribution of qualifying links you agree to the following:
        </h4>
        <p>
          1. At the request of Diginlink from time to time on the website of the
          Diginlink list (for any non owned or operated non owned or any of the
          following appropriate necessary information) to create is pages in
          response to the link qualification and related material planning or
          distribution and/or use.
        </p>
        <p>
          2. Direct cooperation and interaction with reason Diginlink inquiries
          complaints are claims or to the network wherein these connections are
          allowed in distributed online advertising or used including stopping
          the distribution of more qualified links (and related material) and
          when appropriate other questions raised by each advertiser. You agree
          that you are responsible for any breach of this agreement from the use
          of any acts or omissions that result from the approval of a link to a
          third-party website. Diginlink interaction reserves the right to
          prevent the distribution of qualified links all show link to other
          sites.
        </p>
        <h4>4.5 no modification etc. of qualifying links:</h4>
        <p>
          you do not agree with any other forms and any other means and/or
          technical and/or Diginlink of the necessary or available consent and
          /or relative to any method used to modify or block, destroy interfere
          with or disrupt online advertising, the provision of symbols including
          the promotion and presentation of eligible links. You agree that you
          cannot create a special rehabilitation treatment for ear link and
          unless specifically authorized to do so through the relevant
          advertising network in which case all the conditions are agreed to be
          tied to the existing network.
        </p>
        <h4>4.6 termination of qualifying link:</h4>
        <p>
          Diginlink or the relevant network advertiser may terminate any
          qualifying links associated with any engagement you must remove the
          announcement of the skate older participation, including termination
          of the Representation of the appropriate network share after the
          expiration of the approved link. it is approved by the compound, it
          will not be removed in the case of Diginlink of these links forward to
          decide, paid or unpaid for you to decide.
        </p>
        <p>
          4.7 no modification of content you may use advertiser’s change on the
          web or change the size of format, modify or alter any content provided
          otherwise, unless expressly permitted by advertisers on the web. In
          this case, this adjustment should be very much in line with its
          limitation on this network.
        </p>
        <h4>4.8 discontinuing use of qualified links:</h4>
        <p>
          you may at any time discontinue the use of qualifying links by
          removing that qualified links from your site with or without notice of
          Diginlink but you still end up being involved very the terms of duty
          and agreement should confirm to or agreement.
        </p>
      </div>
    </div>
  </div>
  <FooterContent />
</template>
<script>
import MainNavbar from "@/components/MainNavbar";
import FooterContent from "@/components/FooterContent.vue";
export default {
  name: "TermsConditions",
  components: {
    FooterContent: FooterContent,
    MainNavbar: MainNavbar,
  },
};
</script>
<style scoped>
/************** Contact Us ************/
.main-image {
  height: 70vh;
  object-fit: cover;
  object-position: 100% 40%;
}
</style>